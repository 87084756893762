<i18n lang="json5">{
	'ru': {
		'easy_route': 'Вернуть простой маршрут',
		'multi_route': 'Сложный маршрут',
		'from_placeholder': 'Откуда',
		'from_required': 'Укажите город вылета',
		'where_placeholder': 'Куда',
		'where_required': 'Укажите город прибытия',
		'where_equal': 'Аэропорт вылета не должен совпадать с аэропортом прилета',
		'there': 'Туда',
		'back': 'Обратно',
		'date_required': 'Укажите дату',
		'search_tickets': 'Найти билеты',
		'departure_date': 'Дата вылета',
		'add_flight': 'Добавить перелет',
		'TAS': 'Ташкент',
		'SKD': 'Самарканд',
		'BHK': 'Бухара',
		'UGC': 'Ургенч',
		'MOW': 'Москва',
		'IST': 'Стамбул',
		'NCU': 'Нукус',
		'LED': 'С. Петербург',
		'ALA': 'Алматы',
		'NQZ': 'Астана',
		'VNO': 'Вильнюс',
		'RIX': 'Рига',
		'TLL': 'Таллин',
		'TBS': 'Тбилиси',
		'FEG': 'Фергана',
		'DXB': 'Дубай',
	},
	'uz': {
		'easy_route': 'Oddiy marshrutni qaytarish',
		'multi_route': 'Murakkab marshrut',
		'from_placeholder': 'Qayerdan',
		'from_required': 'Uchish shahrini koʼrsating',
		'where_placeholder': 'Qayerga',
		'where_required': 'Qoʼnish shaharni koʼrsating',
		'where_equal': 'Uchish aeroporti qoʼnish aeroporti bilan bir xil boʼlmasligi kerak',
		'there': 'Ketish',
		'back': 'Qaytish',
		'date_required': 'Sanasini koʼrsating',
		'search_tickets': 'Chiptalarni topish',
		'departure_date': 'Uchib ketish sanasi',
		'add_flight': 'Parvoz qoʼshish',
		'TAS': 'Toshkent',
		'SKD': 'Samarqand',
		'BHK': 'Buxoro',
		'UGC': 'Urganch',
		'MOW': 'Moskva',
		'IST': 'Istanbul',
		'NCU': 'Nukus',
		'LED': 'S. Peterburg',
		'FEG': 'Fargʼona',
		'DXB': 'Dubay',
	}
}</i18n>

<template>
	<div :class="{'sm:min-h-[27rem] lg:min-h-[14rem]': $route.name === 'home'}">
		<div
			class="hidden sm:block text-left opacity-75"
			v-if="!simple"
		>
			<span
				class="inline-block w-4 h-4 border-2 border-white rounded-lg align-middle cursor-pointer"
				:class="{'bg-white': moses}"
				@click="mosesToggle"
			/>
			<span
				class="inline-block text-white ml-3 align-middle cursor-pointer"
				@click="mosesToggle"
			>
				<template v-if="moses">{{ $t('easy_route') }}</template>
				<template v-else>{{ $t('multi_route') }}</template>
			</span>
		</div>

		<!--Regular-->
		<div class="mt-6" v-if="!moses">
			<form @submit.prevent="submitForm($event, 'regularForm')">
				<div class="xl:flex">
					<div
						class="xl:flex-grow"
						:class="simple ? 'flex' : 'lg:flex'"
					>
						<div
							class="lg:border-my-gray-400"
							:class="simple ? 'flex-grow border-r' : 'lg:flex-grow lg:border-r'"
						>
							<AirportHints
								v-model="form.departure[0]"
								:autofocus="simple"
								:title="form.departureTitle[0]"
								:placeholder="$t('from_placeholder')"
								:input-class="simple ? 'rounded-r-none' : 'lg:rounded-r-none'"
								:error="departureError"
								:error-text="$t('from_required')"
								:input-class-error="simple ? 'rounded-t-md rounded-tr-none' : 'rounded-t-md lg:rounded-tr-none'"
								@update:title="form.departureTitle[0] = $event"
								@update:error="departureError = $event"
								:destinations="$route.name === 'geotrend' ? [
									{name: $t('TAS'), code: 'TAS'},
									{name: $t('ALA'), code: 'ALA'},
									{name: $t('NQZ'), code: 'NQZ'},
									{name: $t('VNO'), code: 'VNO'},
									{name: $t('RIX'), code: 'RIX'},
									{name: $t('TLL'), code: 'TLL'},
								] : [
									{name: $t('TAS'), code: 'TAS'},
									{name: $t('SKD'), code: 'SKD'},
									{name: $t('MOW'), code: 'MOW'},
									{name: $t('IST'), code: 'IST'},
									{name: $t('FEG'), code: 'FEG'},
								]"
							/>
						</div>

						<div
							class="relative lg:mt-0 xl:border-my-gray-400 xl:border-r"
							:class="simple ? 'flex-grow' : 'lg:flex-grow input-fields-distance'"
						>
							<AirportHints
								v-model="form.arrival[0]"
								:title="form.arrivalTitle[0]"
								:placeholder="$t('where_placeholder')"
								:input-class="simple ? 'rounded-l-none xl:rounded-r-none' : 'lg:rounded-l-none xl:rounded-r-none'"
								:ico-rotate="true"
								:rtl="true"
								:error="arrivalError || identicalCities[0] === true"
								:error-text="identicalCities[0] === true ? $t('where_equal') : $t('where_required')"
								:input-class-error="simple ? 'rounded-t-md rounded-tl-none xl:rounded-tr-none' : 'rounded-t-md lg:rounded-tl-none xl:rounded-tr-none'"
								@update:title="form.arrivalTitle[0] = $event"
								@update:error="arrivalError = identicalCities[0] = $event"
								:destinations="$route.name === 'geotrend' ? [
									{name: $t('TBS'), code: 'TBS'},
								] : [
									{name: $t('TAS'), code: 'TAS'},
									{name: $t('MOW'), code: 'MOW'},
									{name: $t('SKD'), code: 'SKD'},
									{name: $t('IST'), code: 'IST'},
									{name: $t('LED'), code: 'LED'},
								]"
							/>
							<div
								class="absolute z-[1]  top-1/2 flex items-center justify-center border border-gray-300 bg-white rounded-full shadow-md cursor-pointer"
								:class="simple ? 'left-0 mt-0 -translate-x-3 sm:-translate-x-4 -translate-y-[50%] w-6 h-6 sm:w-8 sm:h-8' : '-mt-16 lg:mt-0 lg:left-0 right-4 lg:right-auto lg:-translate-x-4 translate-y-1 sm:-translate-y-0.5 lg:-translate-y-[50%] w-8 h-8'"
								@click="exchange({
									index: 0,
									departure: {
										model: form.departure[0],
										title: form.departureTitle[0]
									},
									arrival: {
										model: form.arrival[0],
										title: form.arrivalTitle[0]
									}
								})"
							>
								<img src="/design/exchange.svg" class="w-4 rotate-90 lg:rotate-0" width="16" height="16" alt="" />
							</div>
						</div>
					</div>

					<div class="lg:flex xl:flex-grow">
						<div class="input-fields-distance xl:mt-0 flex lg:flex-grow lg:w-1/2">
							<div class="flex-grow border-my-gray-400 border-r">
								<AviaCalendar
									v-model="form.departureDate[0]"
									:input-class="'rounded-r-none xl:rounded-l-none'"
									:first-of-two="true"
									:placeholder="$t('there')"
									:error="departureDateError"
									:error-text="$t('date_required')"
									:input-class-error="'rounded-t-md rounded-tr-none xl:rounded-tl-none'"
									@update:error="departureDateError = $event"
								/>
							</div>
							<div class="flex-grow lg:border-my-gray-400 lg:border-r input-fields-distance mt-0">
								<AviaCalendar
									v-model="form.dateBack"
									:input-class="'rounded-l-none lg:rounded-r-none'"
									:previous-date="form.departureDate[0]"
									:placeholder="$t('back')"
									:addCancel="!!form.dateBack"
								/>
							</div>
						</div>

						<div class="input-fields-distance xl:mt-0 sm:flex lg:flex-grow lg:w-1/2">
							<div class="sm:flex-grow sm:w-1/2 lg:w-1/4">
								<Passengers
									@update:adults="form.adults = $event"
									@update:children="form.children = $event"
									@update:infants="form.infants = $event"
									@update:service_class="form.class = $event"
									:adults="form.adults"
									:children="form.children"
									:infants="form.infants"
									:service_class="form.class"
									:input-class="'sm:rounded-r-none lg:rounded-l-none'"
									:simple="simple"
								/>
							</div>

							<div class="sm:hidden mt-3 text-left opacity-75" v-if="!simple">
								<span
									class="inline-block w-4 h-4 border-2 border-white rounded-lg align-middle cursor-pointer"
									:class="{'bg-white': moses}"
									@click="mosesToggle"
								/>
									<span
										class="inline-block text-white ml-3 align-middle cursor-pointer"
										@click="mosesToggle"
									>
									<template v-if="moses">{{ $t('easy_route') }}</template>
									<template v-else>{{ $t('multi_route') }}</template>
								</span>
							</div>

							<div
								class="sm:mt-0 sm:flex-grow text-center sm:w-1/2 lg:w-1/4"
								:class="simple ? 'mt-6' : 'mt-4'"
							>
								<a
									:href="href"
									target="_blank"
									class="relative focus:z-[1] block w-full py-3 sm:py-4 rounded-md sm:rounded-none sm:rounded-r-md bg-gradient-to-b focus:bg-gradient-to-t focus:shadow-highlight hover:bg-gradient-to-t from-my-blue-from to-my-blue-to text-white text-base sm:text-lg font-bold"
									@click="submitForm($event, 'iframeForm')"
									v-if="simple"
								>
									{{ $t('search_tickets') }}
								</a>
								<button
									class="relative focus:z-[1] w-full py-3 sm:py-4 rounded-md sm:rounded-none sm:rounded-r-md bg-gradient-to-b focus:bg-gradient-to-t focus:shadow-highlight hover:bg-gradient-to-t from-my-blue-from to-my-blue-to text-white text-base sm:text-lg font-bold cursor-pointer"
									v-else
								>
									{{ $t('search_tickets') }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>

		<!--Moses-->
		<div class="mt-6" v-else>
			<form @submit.prevent="submitForm($event, 'mosesForm')" id="moses-form">
				<div
					v-for="id in mosesBoxes"
					class="relative moses-box lg:flex"
					:class="{'input-fields-distance': id > 1}"
					:key="id"
					:data-id="id"
				>
					<div class="lg:flex-grow lg:w-1/3 lg:border-my-gray-400 lg:border-r">
						<AirportHints
							v-model="form.departure[id - 1]"
							:title="form.departureTitle[id - 1]"
							:placeholder="$t('from_placeholder')"
							:input-class="'lg:rounded-r-none'"
							:error="id === 1 && departureError"
							:error-text="id === 1 ? $t('from_required') : null"
							:input-class-error="'rounded-t-md lg:rounded-tr-none'"
							@update:title="form.departureTitle[id - 1] = $event"
							@update:error="id === 1 ? departureError = $event : null"
							:destinations="[
								{name: $t('TAS'), code: 'TAS'},
								{name: $t('MOW'), code: 'MOW'},
								{name: $t('SKD'), code: 'SKD'},
								{name: $t('UGC'), code: 'UGC'},
								{name: $t('FEG'), code: 'FEG'},
							]"
						/>
					</div>

					<div class="input-fields-distance relative lg:mt-0 lg:flex-grow lg:w-1/3 lg:border-my-gray-400 lg:border-r" :data-error="identicalCities[id]">
						<AirportHints
							v-model="form.arrival[id - 1]"
							:title="form.arrivalTitle[id - 1]"
							:placeholder="$t('where_placeholder')"
							:input-class="'lg:rounded-none'"
							:ico-rotate="true"
							:error="(id === 1 && arrivalError) || identicalCities[id] === true"
							:error-text="identicalCities[id] === true ? $t('where_equal') : id === 1 ? $t('where_required') : null"
							:input-class-error="'rounded-t-md lg:rounded-t-none'"
							@update:title="form.arrivalTitle[id - 1] = $event"
							@update:error="id === 1 ? arrivalError = identicalCities[id] = $event : identicalCities[id] = $event"
							:destinations="[
								{name: $t('MOW'), code: 'MOW'},
								{name: $t('IST'), code: 'IST'},
								{name: $t('DXB'), code: 'DXB'},
								{name: $t('TAS'), code: 'TAS'},
								{name: $t('LED'), code: 'LED'},
							]"
						/>
						<div
							class="absolute z-[1] lg:left-0 right-4 lg:right-auto top-1/2 -mt-16 lg:mt-0 lg:-translate-x-4 translate-y-1 sm:-translate-y-0.5 lg:-translate-y-[50%] flex items-center justify-center w-8 h-8 border border-gray-300 bg-white rounded-full shadow-md cursor-pointer"
							@click="exchange({
									index: id - 1,
									departure: {
										model: form.departure[id - 1],
										title: form.departureTitle[id - 1]
									},
									arrival: {
										model: form.arrival[id - 1],
										title: form.arrivalTitle[id - 1]
									}
								})"
						>
							<img src="/design/exchange.svg" class="w-4 rotate-90 lg:rotate-0" width="16" height="16" alt="" />
						</div>
					</div>

					<div class="input-fields-distance lg:mt-0 lg:flex-grow lg:w-1/3">
						<AviaCalendar
							v-model="form.departureDate[id - 1]"
							:input-class="'lg:rounded-l-none'"
							:first-of-two="true"
							:placeholder="$t('departure_date')"
							:previous-date="form.departureDate[id - 2]"
							:error="id === 1 && departureDateError"
							:error-text="$t('date_required')"
							:input-class-error="'rounded-t-md lg:rounded-tl-none'"
							@update:error="id === 1 ? departureDateError = $event : null"
						/>
					</div>

					<img
						v-if="id > 1"
						src="/design/cancel.svg"
						class="absolute z-[1] w-6 -top-1.5 -right-1 cursor-pointer"
						@click="removeMosesBox"
						:data-id="id - 1"
						alt
					/>
				</div>

				<div class="lg:flex input-fields-distance">
					<div class="lg:flex-grow lg:w-1/3">
						<Passengers
							@update:adults="form.adults = $event"
							@update:children="form.children = $event"
							@update:infants="form.infants = $event"
							@update:service_class="form.class = $event"
							:adults="form.adults"
							:children="form.children"
							:infants="form.infants"
							:service_class="form.class"
							:input-class="'lg:rounded-r-none'"
						/>
					</div>

					<div class="sm:hidden mt-3 text-left opacity-75">
						<span
							class="inline-block w-4 h-4 border-2 border-white rounded-lg align-middle cursor-pointer"
							:class="{'bg-white': moses}"
							@click="mosesToggle"
						/>
							<span
								class="inline-block text-white ml-3 align-middle cursor-pointer"
								@click="mosesToggle"
							>
							<template v-if="moses">{{ $t('easy_route') }}</template>
							<template v-else>{{ $t('multi_route') }}</template>
						</span>
					</div>

					<div class="mt-4 sm:mt-6 lg:mt-0 lg:flex-grow flex lg:w-2/3">
						<button
							type="button"
							class="relative focus:z-[1] py-3 sm:py-4 bg-gradient-to-b focus:bg-gradient-to-t focus:shadow-highlight hover:bg-gradient-to-t from-my-gray-from to-my-gray-to text-white text-base sm:text-lg font-bold text-center flex-grow w-1/2 rounded-l-md lg:rounded-l-none cursor-pointer"
							@click="addMosesBox"
						>
							{{ $t('add_flight') }}
						</button>
						<button class="relative focus:z-[1] py-3 sm:py-4 bg-gradient-to-b focus:bg-gradient-to-t focus:shadow-highlight hover:bg-gradient-to-t from-my-blue-from to-my-blue-to text-white text-base sm:text-lg font-bold text-center flex-grow w-1/2 rounded-r-md cursor-pointer">
							{{ $t('search_tickets') }}
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
    import AirportHints from './AirportHints'
    import AviaCalendar from './AviaCalendar'
    import Passengers from './Passengers'

    export default {
		name: 'AviaSearchForm',

        components: {
            AirportHints,
			AviaCalendar,
            Passengers,
        },

		props: {
			simple: Boolean,
			requestData: Object,
			selectedDeparture: Object,
			selectedArrival: Object,
		},

        data() {
            return {
                form: {
                    departure: this.selectedDeparture ? [this.selectedDeparture.code] : [],
                    departureTitle: this.selectedDeparture ? [this.selectedDeparture.name] : [],
                    arrival: this.selectedArrival ? [this.selectedArrival.code] : [],
                    arrivalTitle: this.selectedArrival ? [this.selectedArrival.name] : [],
                    departureDate: [],
                    dateBack: null,
                    adults: 1,
                    children: 0,
                    infants: 0,
                    class: 'A'
                },

                departureError: false,
                arrivalError: false,
                identicalCities: [],
                departureDateError: false,

                hintBox: false,
                airports: [],
                moses: false,
                mosesBoxes: 2,
				//calendarDefaultDate: null,
            }
        },
		mounted() {
			this.setFormData()
		},
        methods: {
            /**
             *
             */
            mosesToggle() {
                this.moses = !this.moses;
            },

            /**
             *
             */
            addMosesBox() {
                ++this.mosesBoxes;
            },

            /**
             *
             */
            removeMosesBox(event) {
                const id = event.target.dataset.id;

                this.form.departure.splice(id, 1);
                this.form.departureTitle.splice(id, 1);
                this.form.arrival.splice(id, 1);
                this.form.arrivalTitle.splice(id, 1);
                this.form.departureDate.splice(id, 1);

                if (this.mosesBoxes < 3) {
                    this.moses = false;
                } else {
                    --this.mosesBoxes;
                }
            },

			exchange({index, departure, arrival}) {
				if (departure.model || arrival.model) {
					this.form.departure.splice(index, 1, arrival.model)
					this.form.departureTitle.splice(index, 1, arrival.title)
					this.form.arrival.splice(index, 1, departure.model)
					this.form.arrivalTitle.splice(index, 1, departure.title)
				}
			},

            /**
             *
             */
			validateForm() {
				if (!this.form.departure[0]) {
					this.departureError = true
				}

				if (!this.form.arrival[0]) {
					this.arrivalError = true
				}

				if (!this.form.departureDate[0]) {
					this.departureDateError = true
				}

				return !(this.departureError || this.arrivalError || this.departureDateError)
			},

            submitForm(event, id) {
                if (!this.validateForm()) {
					event.preventDefault()
					return
				}

                let query = [];

                this.identicalCities = [];

				if (id === 'mosesForm') {
					for (let index = 0; index < this.mosesBoxes; ++index) {
						if (this.form.departure[index] === this.form.arrival[index]) {
							this.identicalCities[index + 1] = true;
							return;
						}

						if (!this.form.departure[index] ||
							!this.form.arrival[index] ||
							!this.form.departureDate[index]
						) {
							continue;
						}

						query.push(`${this.form.departure[index]}-${this.form.arrival[index]}-${this.parseDate(this.form.departureDate[index])}`);
					}
                } else {
					if (this.form.departure[0] === this.form.arrival[0]) {
						if (id === 'iframeForm') {
							event.preventDefault()
						}

						this.identicalCities[0] = true;
						return;
					}

					query.push(`${this.form.departure[0]}-${this.form.arrival[0]}-${this.parseDate(this.form.departureDate[0])}`);

					if (!!this.form.dateBack) {
						query.push(`${this.form.arrival[0]}-${this.form.departure[0]}-${this.parseDate(this.form.dateBack)}`);
					}
                }

				if (id === 'iframeForm') {
					return
				}

                query = query.concat([
                    this.form.class,
                    this.form.adults,
                    this.form.children,
                    this.form.infants
                ]).join('_');

				if (query === this.$route.params.aviaQuery && this.$route.name === 'avia-offers') {
					this.$emit('refreshPage')
				} else {
					this.$router.push({
						name: 'avia-offers',
						params: {aviaQuery: query}
					})
				}
            },

            /**
             *
             */
            parseDate(date) {
                return `${date.getFullYear()}${this.addZero(date.getMonth() + 1)}${this.addZero(date.getDate())}`
            },

            setFormData() {
				const serverDate = new Date(this.$store.getters['serverDate']).setHours(0, 0, 0, 0)

                if (this.requestData) {
                    this.form = {
                        departure: [],
                        departureTitle: [],
                        arrival: [],
                        arrivalTitle: [],
                        departureDate: [],
                        dateBack: null,
                        adults: 1,
                        children: 0,
                        infants: 0,
                        class: 'A'
                    }

                    this.form.adults = this.requestData.adults;
                    this.form.children = this.requestData.children;
                    this.form.infants = this.requestData.infants;
                    this.form.class = this.requestData.serviceClass;

                    const directions = this.requestData.directions;

                    for (let direction of directions) {
						const departureDate = new Date(Date.parse(direction.dateISO))

                        this.form.departure.push(direction.departureAirportCode);
                        this.form.departureTitle.push(direction.departureAirport);
                        this.form.arrival.push(direction.arrivalAirportCode);
                        this.form.arrivalTitle.push(direction.arrivalAirport);

						if (departureDate >= serverDate) {
							this.form.departureDate.push(departureDate);
						} else {
							this.form.departureDate.push(null);
						}
                    }

                    if (directions.length === 2 &&
                        directions[0].departureAirportCode === directions[1].arrivalAirportCode &&
                        directions[0].arrivalAirportCode === directions[1].departureAirportCode
                    ) {
						const dateBack = new Date(Date.parse(directions[1].dateISO))

						if (dateBack >= serverDate) {
							this.form.dateBack = dateBack;
						}
                    } else if (directions.length >= 2) {
                        this.moses = true;
                        this.mosesBoxes = directions.length;
                    }
                }
            },
        },
		computed: {
			href() {
				let query = []
				let error = false

				if (!this.form.departure[0] ||
					!this.form.arrival[0] ||
					!this.form.departureDate[0] ||
					this.form.departure[0] === this.form.arrival[0]
				) {
					error = true
				}

				if (error) {
					return '#'
				}

				query.push(`${this.form.departure[0]}-${this.form.arrival[0]}-${this.parseDate(this.form.departureDate[0])}`)

				if (!!this.form.dateBack) {
					query.push(`${this.form.arrival[0]}-${this.form.departure[0]}-${this.parseDate(this.form.dateBack)}`)
				}

				query = query.concat([
					this.form.class,
					this.form.adults,
					this.form.children,
					this.form.infants
				]).join('_')

				return `/${this.$i18n.locale}/avia/offers/${query}`
			}
		}
    }
</script>
