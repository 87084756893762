<i18n lang="json5">{
	ru: {
		economy: 'Эконом',
		business: 'Бизнес',
		adults: 'Взрослые',
		adults_hint: 'старше 12 лет',
		children: 'Дети',
		children_hint: 'от 2 до 12 лет',
		infants: 'Младенцы',
		infants_hint: 'до 2 лет',
		more_ticket_title: 'Нужно больше 9 билетов?',
		more_tickets_tooltip: 'К сожалению, забронировать онлайн более 9 билетов, невозможно.\nРекомендуем оформить несколько броней, либо обратиться в нашу головную кассу по адресу Ташкент ул. Гагарина, 66 или по телефону +99871 200 48 00',
		passenger_count: '0 пассажиров | {n} пассажир | {n} пассажира | {n} пассажиров',
	},
	uz: {
		economy: 'Ekonom',
		business: 'Biznes',
		adults: 'Kattalar',
		adults_hint: '12 yoshdan kattalar',
		children: 'Bolalar',
		children_hint: '2 yoshdan 12 yoshgacha',
		infants: 'Chaqaloqlar',
		infants_hint: '2 yoshgacha',
		more_ticket_title: '9 tadan ortiq chipta kerakmi?',
		more_tickets_tooltip: 'Afsuski, 9 dan ortiq chiptani onlayn tartibda bronlash mumkin emas. Biz sizga bir necha marotaba bronlashni, yoki quyidagi manzilda joylashgan bosh kassamizga kelishni, yoki quyidagi telefon raqami orqali murojaat qilishni tavsiya qilamiz: Toshkent sh., Gagarin ko‘chasi, 66-uy\nTel: +998 71 200 48 00',
		passenger_count: '0 yoʼlovchi | {n} yoʼlovchi',
		seats_count: '0 | Shoshiling! Shu narxdan {n} joy qoldi.',
	}
}</i18n>

<template>
    <div
		ref="passengers"
		class="relative h-full"
		v-click-outside="onClickOutside"
	>
        <div
			ref="input"
			class="relative focus:z-[1] h-full py-3 sm:py-4 pl-4 bg-white text-black text-base text-left sm:text-lg font-bold cursor-pointer rounded-md focus-visible:z-[1] focus-visible:outline-none focus-visible:shadow-highlight"
			:class="[inputClass, {'z-[1] shadow-highlight': hintBox}]"
			tabindex="0"
			@click="hintBox = true"
			@focus="hintBox = true"
		>
			<div class="lg:absolute lg:left-0 lg:right-0 lg:pl-4 pr-10 truncate">
				{{ $tc('passenger_count', total) }}
			</div>
		</div>
        <img src="/design/user-field.svg" class="absolute z-[1] right-4 top-3 sm:top-5 w-input-ico pointer-events-none" alt />

        <transition name="fade">
            <div
				class="absolute w-72 bg-white p-4 border shadow-hint-top rounded-md z-40 text-left passengers-dropdown"
				v-if="hintBox"
			>
                <div
					v-for="passenger in passengerTypes"
					:key="passenger.type"
					class="flex items-center w-full mb-1.5"
				>
                    <div class="grow pr-2">
						<div class="text-base text-black font-bold">{{ passenger.title }}</div>
						<div class="text-gray-500 text-xs leading-3">{{ passenger.hint }}</div>
					</div>
                    <div class="flex text-right align-top border border-gray-200 p-2 rounded-md shadow-inner">
                        <img src="/design/minus.svg" class="plus-minus-pointer" @click="removePassenger(passenger.type)" alt />
                        <span class="w-10 text-center">{{ passenger.quantity }}</span>
                        <img src="/design/add.svg" class="plus-minus-pointer" @click="addPassenger(passenger.type)" alt />
                    </div>
                </div>

				<template v-if="!simple">
					<div class="mt-6 text-center text-my-blue relative">
						<span
							class="underline cursor-pointer"
							v-tooltip="{
								container: false,
								content: $t('more_tickets_tooltip'),
								classes: 'dark text-left whitespace-pre-line'
							}"
						>
							{{ $t('more_ticket_title') }}
						</span>
					</div>

<!--					<hr class="border-gray-300 my-6"/>

					<ul class="flex pb-2">
						<li
							v-for="classType in classTypes"
							class="capitalize cursor-pointer bg-radio-off bg-contain bg-no-repeat flex-grow"
							:class="{'bg-radio-on': service_class === classType.type}"
							@click="checkClass(classType.type)"
						>
							<span class="font-bold pl-8">{{ classType.title }}</span>
						</li>
					</ul>-->
				</template>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        props: {
            adults: Number,
            children: Number,
            infants: Number,
			service_class: String,
            inputClass: String,
			simple: Boolean,
        },

        data() {
            return {
                hintBox: false,
                maxPassengers: 9,
            }
        },

		mounted() {
			this.$nextTick(() => {
				const passengersRef = this.$refs.passengers
				if (passengersRef) {
					const form = passengersRef.closest('form')
					if (form) {
						form.addEventListener('focusin', this.onFocusin)
					}
				}
			})
		},

        computed: {
			classTypes() {
				return [
					{
						type: 'E',
						title: this.$t('economy'),
					},
					{
						type: 'B',
						title: this.$t('business'),
					}
				]
			},
			passengerTypes() {
				return [
					{
						type: 'adults',
						title: this.$t('adults'),
						hint: this.$t('adults_hint'),
						quantity: this.adults
					},
					{
						type: 'children',
						title: this.$t('children'),
						hint: this.$t('children_hint'),
						quantity: this.children
					},
					{
						type: 'infants',
						title: this.$t('infants'),
						hint: this.$t('infants_hint'),
						quantity: this.infants
					},
				]
			},
            /**
             *
             */
            currentType() {
                for (let classType of this.classTypes) {
                    if (classType.type === this.service_class) {
                        return classType.title;
                    }
                }
            },

            /**
             *
             */
            total() {
                return this.adults + this.children + this.infants;
            }
        },

        methods: {
			onFocusin(e) {
				if (e.target !== this.$refs.input) {
					this.hintBox = false
				}
			},
            addPassenger(type) {
                if (this.total + 1 > this.maxPassengers) {
                    return;
                }

                const number = this[type] + 1;

                if (type === 'infants' && number > this.adults) {
                    return;
                }

                this.$emit(`update:${type}`, number);
            },

            /**
             *
             */
            removePassenger(type) {
                if (this[type] < 1) {
                    return;
                }

                const number = this[type] - 1;

                if (type === 'adults') {
                    if (number < 1) {
                        return;
                    }

                    if (this.infants > number) {
                        this.$emit('update:infants', number);
                    }
                }

                this.$emit(`update:${type}`, number);
            },

            /**
             *
             */
            checkClass(type) {
                this.$emit('update:service_class', type);
            },

			onClickOutside() {
				this.hintBox = false
			}
        },
    }
</script>
